import jQuery from 'jquery';
import noUiSlider from 'nouislider';

jQuery(function($) {
  console.log('App loaded');
  $('[data-toggle="menu-open"]').on('click', function(e) {
    e.preventDefault();
    var target = $(this).attr('data-target');
    $(this)
      .parent()
      .toggleClass('active')
      .siblings('li')
      .removeClass('active');

    $('.dropdown-menu#' + target)
      .toggleClass('open')
      .siblings('.dropdown-menu')
      .removeClass('open');
  });

  $('[data-toggle="mobile-menu-toggle"]').on('click', function(e) {
    e.preventDefault();

    $('.header-mobile-menu').toggleClass('open');
    $(document.body).toggleClass('menu-open');
  });

  var slider = document.getElementById('filterRange');
  if (slider) {
    noUiSlider.create(slider, {
      start: [1],
      connect: true,
      step: 1,
      range: {
        min: 1,
        max: 6
      },
      pips: { mode: 'values', density: 100, values: [1, 2, 3, 4, 5, 6] }
    });
  }

  $('[data-toggle="legal-tab"]').on('click', function(e) {
    e.preventDefault();
    var target = $(this).attr('data-target');

    $(this)
      .parent()
      .addClass('active')
      .siblings('li')
      .removeClass('active');

    $('div' + target)
      .addClass('active')
      .siblings('.legal')
      .removeClass('active');
  });
});
